import * as React from 'react';
import APPSFLYER_CONFIG, {
  ACTIVE_WOMAN_SCRIPT_URL,
  DEFAULT_SMART_SCRIPT_URL,
  MILLENIAL_PROFESSIONAL_SCRIPT_URL,
  PROMO_SCRIPT_URL,
  REFERRAL_SMART_SCRIPT_URL,
  YOUNG_ENTREPRENEUR_SCRIPT_URL,
} from '../configs/appsflyer';

export default function useSmartScript(variant = 'default') {
  const mapUrl = new Map([
    ['referral', REFERRAL_SMART_SCRIPT_URL],
    ['activeWoman', ACTIVE_WOMAN_SCRIPT_URL],
    ['millenialProfessional', MILLENIAL_PROFESSIONAL_SCRIPT_URL],
    ['youngEntrepreneur', YOUNG_ENTREPRENEUR_SCRIPT_URL],
    ['promo', PROMO_SCRIPT_URL],
  ]);
  const url = mapUrl.get(variant) || DEFAULT_SMART_SCRIPT_URL;
  const [smartScriptUrl, setSmartScriptUrl] = React.useState(url);
  const [isSmartScriptLoaded, setIsSmartScriptLoaded] = React.useState(false);

  React.useEffect(() => {
    if (isSmartScriptLoaded) {
      APPSFLYER_CONFIG.oneLinkURL = url;
      const { clickURL } = window.AF_SMART_SCRIPT.generateOneLinkURL(APPSFLYER_CONFIG);
      setSmartScriptUrl(clickURL || url);
    }
  }, [isSmartScriptLoaded]);

  return {
    smartScriptUrl,
    setSmartScriptUrl,
    setIsSmartScriptLoaded,
  };
}
