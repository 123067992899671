const CONTACT_CONFIG = {
  PT_CONTACT: {
    PHONE: '(+62) 31 9921 0252',
    WHATSAPP: '+62-851-6310-6672',
    EMAIL: 'hello@depositobpr.id',
  },
  DIREKTORAT_CONTACT: {
    WHATSAPP: '+62-853-1111-1010',
  },
  FOOTER_CONTACT: {
    PHONE: '(031) 9920 6672',
    WHATSAPP: '0851 6310 6672',
    WHATSAPP_LINK: 'https://api.whatsapp.com/send?phone=6285163106672',
  },
  SOCMED: {
    YOUTUBE: 'https://www.youtube.com/channel/UCzdxX1sq7jl4kli5XMzdqvQ',
    TIKTOK: 'https://www.tiktok.com/@depositobpr.id',
    X: 'https://x.com/depositobpr_id',
    TELEGRAM: 'https://t.me/depositobprid',
    INSTAGRAM: 'https://www.instagram.com/depositobpr.id',
  },
};

export default CONTACT_CONFIG;
