export const DEFAULT_SMART_SCRIPT_URL = 'https://depositobpr.onelink.me/9H7s/9j3eyd14';
export const REFERRAL_SMART_SCRIPT_URL = 'https://depositobpr.onelink.me/9H7s/k2bni1hu';
export const ACTIVE_WOMAN_SCRIPT_URL = 'https://depositobpr.onelink.me/3kQH/iuj61dlh';
export const MILLENIAL_PROFESSIONAL_SCRIPT_URL = 'https://depositobpr.onelink.me/3kQH/c3n5kqat';
export const YOUNG_ENTREPRENEUR_SCRIPT_URL = 'https://depositobpr.onelink.me/3kQH/u6zgjnvu';
export const PROMO_SCRIPT_URL = 'https://link.depositobpr.id/3kQH/sctmb80h';
export const ONELINK_SCRIPT_URL = 'https://onelinksmartscript.appsflyer.com/onelink-smart-script-latest.js';
const oneLinkURL = 'https://depositobpr.onelink.me/9H7s';
const mediaSource = { keys: ['utm_source'], defaultValue: 'organic%20-%20web' };
const campaign = { keys: ['utm_campaign'], defaultValue: 'organic%20-%20web' };
const channel = { keys: ['utm_medium'], defaultValue: 'organic' };
const customSsUi = { paramKey: 'af_ss_ui', defaultValue: 'true' };

const APPSFLYER_CONFIG = {
  oneLinkURL,
  afParameters: {
    mediaSource,
    campaign,
    channel,
    afCustom: [
      customSsUi,
    ],
  },
};

export default APPSFLYER_CONFIG;
