import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar';
import { Footer } from '../components/footer';
import '../styles/global.css';
import Wrapper from '../components/Wrapper';

const App = () => {
  const [formData, setFormData] = useState({
    depositoAwal: null,
    tenor: null,
    bunga: null,
    pajak: 20,
  });

  const [result, setResult] = useState({
    bungaSebelumPajak: 0,
    bungaSetelahPajak: 0,
    danaSetelahTenor: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: Number(value),
    }));
  };

  useEffect(() => {
    const {
      depositoAwal, tenor, bunga, pajak,
    } = formData;
    if (depositoAwal && tenor && bunga && pajak) {
      const bungaSebelumPajak = (depositoAwal * (bunga / 12) * tenor) / 100;
      const bungaSetelahPajak = bungaSebelumPajak * ((100 - (pajak || 0)) / 100);
      const danaSetelahTenor = depositoAwal + bungaSetelahPajak;
      setResult({
        bungaSebelumPajak,
        bungaSetelahPajak,
        danaSetelahTenor,
      });
    }
  }, [formData]);

  return (
    <Wrapper>
      <Navbar />
      <div className="container mx-auto grid grid-cols-2 py-36">
        <div className="form-wrapper">
          <h1>Kalkulator Deposito</h1>
          <div className="flex items-center py-3">
            <label htmlFor="depositoAwal" className="mr-2">Dana Deposito Awal:</label>
            <input
              type="number"
              name="depositoAwal"
              value={formData.depositoAwal || ''}
              onChange={handleChange}
              placeholder="10000000"
            />
          </div>

          <div className="flex items-center py-3">
            <label htmlFor="tenor" className="mr-2">Tenor Deposito (Bulan):</label>
            <input
              type="number"
              name="tenor"
              value={formData.tenor || ''}
              onChange={handleChange}
              placeholder="3"
            />
          </div>

          <div className="flex items-center py-3">
            <label htmlFor="bunga" className="mr-2">Bunga Deposito Tahunan (%):</label>
            <input
              type="number"
              name="bunga"
              value={formData.bunga || ''}
              onChange={handleChange}
              placeholder="5.25"
              step="0.01"
            />
          </div>

          <div className="flex items-center py-3">
            <label htmlFor="pajak" className="mr-2">Pajak Bunga Deposito (%):</label>
            <input
              type="number"
              name="pajak"
              value={formData.pajak || ''}
              placeholder="Pajak"
              readOnly
            />
          </div>
        </div>
        <div style={{ marginTop: '30px' }}>
          <h2>Hasil Kalkulasi</h2>
          <ul>
            <li>Bunga (Sebelum Pajak): {result.bungaSebelumPajak}</li>
            <li>Bunga (Setelah Pajak): {result.bungaSetelahPajak}</li>
            <li>Dana Setelah Tenor Berakhir: {result.danaSetelahTenor}</li>
          </ul>
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
};

export default App;
